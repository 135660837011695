import React, { useState, useEffect } from "react"

import { Formik, Form } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import { setCookie, parseCookies } from 'nookies'

import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"
import { DatePicker, Input, Select } from "components/anti/forms/forms"
import { NavTabs, TabPane } from "components/anti/tabs/tabs"
import Seo from "components/seo"

import icInfo from "assets/img/bpjs/ic_info_white.svg"
import circle from "assets/img/roll-animate.svg"


import { bpjsInquiryQuery } from "components/utils/streamworx"

const BpjsPayment = () => {
    const cookies = parseCookies()

    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    const streamworx_config = { context: { clientName: 'streamworx', headers: { authorization: authTokenNew, }, } }

    const [bpjsInquiry] = useMutation(bpjsInquiryQuery, streamworx_config)


    const rentangWaktuOpt = [
        { value: 'Setiap Hari', label: 'Setiap Hari' },
        { value: 'Setiap Minggu', label: 'Setiap Minggu' },
        { value: 'Setiap Bulan', label: 'Setiap Bulan' },
        { value: 'Setiap Tahun', label: 'Setiap Tahun' },
    ]

    const [tagihanDate, setTagihanDate] = useState(moment().toDate());
    const [debitDate, setDebitDate] = useState(new Date());
    const [rentangWaktu, setRentangWaktu] = useState(rentangWaktuOpt[0]);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [errMsg, setErrMsg] = useState("")


    // bayar tagihan function
    const bayarTagihanValidation = Yup.object({
        noVa: Yup.string()
            .required("Masukkan Nomor virtual account anda").matches(/^\d+$/, "Format nomor VA yang anda masukkan salah")

    })

    const initialBayarTagihan = {
        noVa: "",
    }

    const handleSubmit1 = (values, { setSubmitting, resetForm }) => {
        setBtnDisabled(true)
        const currentMonth = new Date().getMonth() + 1
        const currentYear = new Date().getFullYear()
        const month = tagihanDate.getMonth() + 1
        const year = tagihanDate.getFullYear()

        const currentDate = currentMonth + (currentYear * 12)
        const date = month + (year * 12)
        const periode = date - currentDate + 1


        const show = { ...values, periode }

        bpjsInquiry({
            variables: { ...show }
        }).then(res => {
            const status = res.data.bpjsInquiry.status
            const msg = res.data.bpjsInquiry.message
            console.log("result", res)
            if (status === "OK") {
                const detail = res.data.bpjsInquiry.detail
                setCookie(null, 'detailPayment', JSON.stringify(detail), {
                    maxAge: 12 * 60 * 60,
                    path: '/',
                })
                navigate("/bpjskesehatan/payment/confirmation")
            } else {
                setBtnDisabled(false)
                setErrMsg(msg)
            }
        }).catch(err => console.log("err", err))

        // setShowModal("bayarTagihanModal")
        // console.log(show)
        // console.log("current date", currentMonth, currentYear, "total = ", currentDate)
        // console.log("date", month, year, "total = ", date)
        // console.log("period", period)

    }


    //Auto Debit function
    const initialAutoDebit = {
        noVa: "",
        namaLangganan: "",
    }

    const handleSubmit2 = (values, { setSubmitting, resetForm }) => {
        const date = moment(debitDate).format("MMM DD, YYYY")
        const rentangValue = rentangWaktu.value
        const show = { ...values, date, rentangValue }
    }

    useEffect(() => {
        const authToken = cookies.authToken
        if (!authToken) {
            navigate("/login")
            setCookie(null, 'loginDirect', "/bpjskesehatan/payment")
        }
    }, [])

    return (
        <Layout>
            <Seo title="BPJS Payment" />
            <section className="py-main sc-bpjs-payment">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/bpjskesehatan"}
                    >
                        Kembali
                    </Button>
                    <h2>Bayar Tagihan BPJS</h2>
                    <p className="mb-0">Klik link di bawah untuk melihat riwayat pembayaran BPJS Kesehatan anda.</p>
                    <Button variant="link" link="/bpjskesehatan/riwayat">
                        Lihat Riwayat Pembayaran
                    </Button>
                    <hr className="" />
                    <p>Lengkapi informasi di bawah ini untuk melakukan transaksi baru</p>
                    <div className="bpjs-nav bpjs-payment-nav position-relative">
                        <NavTabs
                            data={[
                                { name: "BAYAR TAGIHAN", target: "bayar-tagihan" },
                                // { name: "AUTO DEBIT", target: "auto-debit" },
                            ]}
                        />
                    </div>
                    <TabPane id="bayar-tagihan" className="active">
                        <Formik
                            initialValues={initialBayarTagihan}
                            validationSchema={bayarTagihanValidation}
                            onSubmit={handleSubmit1}
                        >
                            {({
                                handleChange,
                                touched,
                                errors,
                            }) => (
                                <Form>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <Input
                                                // floatingLabel
                                                variant={"underline"}
                                                label={"Nomor Virtual Account"}
                                                placeholder="Masukkan Nomor Virtual Account"
                                                as="input"
                                                id="noVa"
                                                name="noVa"
                                                onChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <div className="field field-underline">
                                                <label htmlFor="date" >
                                                    Bayar Sampai
                                                </label>
                                                <div className="form-control-wrapper form-date">
                                                    <DatePicker
                                                        selected={tagihanDate}
                                                        onChange={(date) => setTagihanDate(date)}
                                                        placeholder="Tanggal Tes"
                                                        className="date-border"
                                                        touched={touched}
                                                        errors={errors}
                                                        dateFormat={"MMMM yyyy"}
                                                        showMonthYearPicker
                                                        minDate={moment().subtract(1, 'months').endOf('month').toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            <div className="d-flex">
                                                <img src={icInfo} alt="ico" className="mr-3" />
                                                <p className="mb-0">Proses verifikasi pembayaran maksimal 1 x 24 jam hari kerja.</p>
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            <Button variant="primary" type="submit" className="btn-block mt-4" disabled={btnDisabled}>
                                                {btnDisabled ? (
                                                    <img src={circle} className="img-fluid loading" />
                                                ) : (
                                                    "Lanjut"
                                                )}
                                            </Button>
                                            <div className="invalid-feedback">{errMsg}</div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </TabPane>
                    <TabPane id="auto-debit">
                        <Formik
                            initialValues={initialAutoDebit}
                            validationSchema={bayarTagihanValidation}
                            onSubmit={handleSubmit2}
                        >
                            {({
                                handleChange,
                                touched,
                                errors,
                            }) => (
                                <Form>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <Input
                                                // floatingLabel
                                                variant={"underline"}
                                                label={"Nomor Virtual Account"}
                                                placeholder="Masukkan Nomor Virtual Account"
                                                as="input"
                                                id="noVa"
                                                name="noVa"
                                                onChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <Input
                                                // floatingLabel
                                                variant={"underline"}
                                                label={"Nama Langganan (Opsional)"}
                                                placeholder="Ketik nama langganan"
                                                as="input"
                                                id="namaLangganan"
                                                name="namaLangganan"
                                                onChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <Select
                                                variant="underline"
                                                id="rentangWaktu"
                                                name="rentangWaktu"
                                                label="Rentang Waktu Pembelian"
                                                options={rentangWaktuOpt}
                                                onChange={(id, value) => setRentangWaktu(value)}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <div className="field field-underline">
                                                <label htmlFor="date" >
                                                    Tanggal Mulai Auto Debit
                                                </label>
                                                <div className="form-control-wrapper form-date">
                                                    <DatePicker
                                                        dateFormat="MMMM yyyy"
                                                        selected={debitDate}
                                                        onChange={(date) => setDebitDate(date)}
                                                        name="date"
                                                        placeholder="Tanggal Tes"
                                                        className="date-border"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            <Button variant="primary" type="submit" className="btn-block mt-4">
                                                Simpan Langganan
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </TabPane>
                </div>
            </section>
            {/* <Modal
                id="bayarTagihanModal"
                title="Pembayaran BPJS Kesehatan"
                isShowing={showModal}
                hide={closeModal}
                className="modal-md bayar-tagihan-modal"
            >
                <div className="bayar-tagihan-modal-content mt-5 animated fadeInDown delayp5">
                    <p className="caption bg-light modal-container py-2">Detail pembayaran</p>
                    <div className="modal-container mb-5">
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Va Kepala Keluarga</p>
                            <p className="mb-4">00000002615942586</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Va</p>
                            <p className="mb-4">00000002615942586</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">Nama</p>
                            <p className="mb-4">Budi Antono</p>
                        </div>
                    </div>
                    <p className="caption bg-light modal-container py-2">Tagihan</p>
                    <div className="mb-4 modal-container">
                        <p className="caption mb-0">Nominal yang perlu dibayar</p>
                        <h2 className="mb-4">Rp79.000</h2>
                    </div>
                    <div className="modal-container">
                        <Button variant="primary" className="btn-block" onClick={handleSubmitModal}>
                            Lanjut
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                id="tagihanSuccess"
                title="Pembayaran Berhasil"
                text="Terima kasih telah melakukan pembayaran BPJS Kesehatan di Dompet Aman."
                isShowing={showModal}
                img={icSuccess}
                imgClassName="mb-4"
                imgAlt="IcSuccess"
                className="modal-md bayar-tagihan-modal"
            >
                <div className="bayar-tagihan-modal-content mt-5 animated fadeInDown delayp6">
                    <p className="caption bg-light modal-container py-2">Detail pembayaran</p>
                    <div className="modal-container mb-5">
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Va Kepala Keluarga</p>
                            <p className="mb-4">00000002615942586</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Va</p>
                            <p className="mb-4">00000002615942586</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">Nama</p>
                            <p className="mb-4">Budi Antono</p>
                        </div>
                    </div>
                    <div className="mb-4 modal-container">
                        <p className="caption mb-0">Nominal yang telah dibayar</p>
                        <h2 className="mb-4">Rp79.000</h2>
                    </div>
                    <div className="modal-container">
                        <Button variant="primary" className="btn-block" onClick={closeModal}>
                            Kembali ke Beranda
                        </Button>
                    </div>
                </div>
            </Modal> */}
        </Layout>
    )
}

export default BpjsPayment